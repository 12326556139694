@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import "~bootstrap/scss/bootstrap";

html, body {
  font-family: 'Roboto', sans-serif;
}

h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  color: #222222;
}

p {
  font-size: 18px;
}

@media (max-width: 576px) {
  h1 {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }
}
